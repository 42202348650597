@import "../../../styles/variables.scss";

.sidebar {
  background-color: lightgrey;
  position: fixed;
  top: 10vh;
  left: 0;
  padding: 2rem 0;
  height: 80vh;
  border-radius: 10px;
  margin-top: 5px;
  margin-left: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px 0px;

  .burger {
    margin: 0 0 10px 0;
  }

  .actionButtons {
    margin: 45px 0;

    .imageLink {
      margin: 5px 0;
    }

    .loginCircle {
      margin: 5px 0;
    }
  }

  .shortImage {
    width: 40px;
    margin: 0 auto;
  }

  h2.title {
    font-size: 12px;
  }

  .profileContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: 1 rem;
    }

    .profileContents {
      p.name {
        padding: 0;
        color: gray;
      }

      p {
        color: lightcyan;
      }
    }
  }

  .contentsContainer {
    font-size: 17px;
    font-weight: 300;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: left;
      margin: 5px 10px 25px 5px;
      padding: 0;
      text-align: left;
      &:hover {
        background-color: gray;
        cursor: pointer;
      }

      &:active {
        background-color: $blue-1;
      }
    }

    .selected {
      color: white;
      background-color: $blue-3;
      font-weight: 500;
    }

    .noSelected {
      font-weight: 300;
    }
    
    .title{
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: left;
      margin: 5px 10px 10px 2px;
      text-align: left;
    }

    .separatorLine{
      width: 70%;
      margin: 15px auto 30px auto; 
      border-top: 3px solid gray;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px 0px;
    }

    .spacer{
      height: 30px;
    }
  }
}

.refIndication{
  font-size: 21px;
  font-weight: 700;
  margin: 45px 0 0 0;
}