@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  display: flex;
  margin: 30px 0;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 32px;
  @include width-viewport;

  .logo {
    height: 110px;
  }

  @media screen and (max-width: $breakpoint-xxl) {
    max-width: 80%;
  }

  @media screen and (max-width: $breakpoint-xlg) {
    max-width: 90%;
  }
}