@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.mainPage {
  background-color: white;
  display: block;
  margin: 120px auto 0 auto;
  width: 90%;
}

.navbar {
  width: 100vw;
  position: fixed;
  background: $gray-1;
  top: 0;
}

.button {
  margin-bottom: 40px;
}

.button .demoButton {
  border-radius: 9px;
  width: 400px;
  font-size: 20px;
}

.copyright {
  margin-bottom: 25px;
}

.container {
  width: 100%;
  margin: 0 auto;

  .title {
    color: $green-2;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 15px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.5rem;
    }
    @media screen and (min-width: $breakpoint-xlg) {
      font-size: 2.5rem;
    }
  }

  .accordion {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    padding: 0;
    @include width-viewport;

    color: $blue-3;
    font-size: 20px;
    text-align: left;
    .accents {
      color: $green-1;
    }
  }

  .planInstructions {
    width: 100%;
    margin: 0 auto;
    .text {
      font-size: $txtDetail;
      text-align: left;
    }

    .video {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3rem 0;
    }
  }

  .loadData {
    // width: 98%;
    margin: 0 15px;
  }

  .fileManager {
    background-color: white;
    width: 80%;
    margin: 0 auto;

    .elementFlex {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 0px auto;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      .element {
        margin: 5px auto;
      }
    }

    .elementBlock {
      padding: 15px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      .elementRow {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        gap: 15px;
      }
    }
  }

  .selectButton {
    color: $blue-3 !important;
    background-color: $green-2 !important;
    width: 360px;
    font-size: 13px;
    padding: 11px 0;
  }

  .selectButton:hover {
    background-color: $blue-3 !important;
    color: white !important;
    box-shadow: 10px 10px 10px rgba(134, 176, 204, 1);
  }

  .fileInput {
    margin: 0 auto;
    padding: 15px 0 15px 15px;
    border-radius: 30px;
    color: $blue-3;
    background-color: $green-2;
    width: 350px;
  }

  .fileInput:hover {
    background-color: $blue-3 !important;
    color: white !important;
    box-shadow: 10px 10px 10px rgba(134, 176, 204, 1);
  }

  .uploadButton {
    color: $blue-3 !important;
    background-color: $green-2 !important;
    width: 360px;
    font-size: 15px;
    border-radius: 30px;
    padding: 11px 0;
    border: none;
  }

  .uploadButton:hover {
    background-color: $blue-3 !important;
    color: white !important;
    box-shadow: 10px 10px 10px rgba(134, 176, 204, 1);
  }

  .buttonMessage {
    display: flex;
    justify-content: center;
    gap: 5px;
    .buttonIcon {
      display: inline;
    }
    .buttonText {
      margin-top: 3px;
      display: inline;
    }
  }
}

.infoMessage {
  background-color: $green-5;
  border-radius: 10px;
  color: $blue-3;
  font-size: $txtDetail;
  justify-content: center;
  line-height: 16px;
  letter-spacing: 0px;
  margin: 32px auto 32px auto;
  padding: 24px;
  text-align: left;
  width: 70%;
}
