@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 84px;
  margin-bottom: 0px;
  row-gap: 30px;
  @include width-viewport;

  .content {
    width: 100%;
    justify-content: center;

    .image {
      width: 100%;
    }

    .legend {
      color: $blue-3;
      text-align: left;
      font-size: 16px;
      line-height: 20px;
      align-self: center;
    }
  }
}

@media screen and (min-width: $breakpoint-xs) {
  .container {
    margin-bottom: 20px;
  }
}