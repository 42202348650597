@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

button.container {
  font-weight: 700;
  font-size: 16px;
  text-transform: inherit;
  border-radius: 37px;
  min-width: 100px;
  color: $gray-1;

  &.text {
    color: $blue-3;
    font-size: 16px;
    font-weight: 300;

    &:hover {
      background: transparent;
      color: $gray-3;
      font-weight: 400;
    }
    &:disabled {
      color: $gray-3;
      opacity: 0.4;
    }
  }

  &.containedButton {
    &.primaryColor {
      background: $blue-2;

      &:hover {
        @include button-contained-hover();
      }
    }

    &.secondaryColor {
      background: $green-1;

      &:hover {
        @include button-contained-hover();
      }
    }

    &.tertiaryColor {
      background: $green-3;

      &:hover {
        @include button-contained-hover();
      }
    }

    &.disabled {
      background: $gray-7;
      color: $gray-6;
    }
  }
  &.outlinedButton {
    &.primaryColor {
      color: $blue-2;
      border-color: $blue-2;

      &:hover {
        color: $gray-3;
        border-color: $gray-3;
        @include button-outlined-hover();
        background: $gray-1;
      }
    }

    &.secondaryColor {
      color: $green-1;
      border-color: $green-1;

      &:hover {
        @include button-outlined-hover();
      }
    }

    &.tertiaryColor {
      color: $green-3;
      border-color: $green-3;

      &:hover {
        @include button-outlined-hover();
      }
    }

    &.disabled {
      @include button-outlined-hover();

      opacity: 0.5;
    }
  }

  &.textButton {
    color: $blue-3;
    background: $gray-1;

    &:hover {
      text-decoration: underline $green-2 3px;
      text-underline-offset: 5px;
    }

    &.disabled {
      color: $gray-6;
    }
  }
}

.icon {
  width: fit-content;
}
