@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.CalculationOnline {
  display: block;
  background-color: white;
  margin-top: 120px;
  width: 100%;
}

.navbar {
  width: 100vw;
  position: fixed;
  background: $gray-1;
  top: 0;
  z-index: 10000;
}

.button {
  margin-bottom: 40px;
}

.button .demoButton {
  border-radius: 9px;
  width: 400px;
  font-size: 20px;
}

.copyright {
  margin-bottom: 25px;
}

.container {
  width: 100%;
  margin: 0 auto;
  
  .title {
    color: $green-2;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 15px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.5rem;
    }
    @media screen and (min-width: $breakpoint-xlg) {
      font-size: 2.5rem;
    }
  }

  .accordion{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  
  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    @include width-viewport;

    color: $blue-3;
    font-size: 20px;
    text-align: left;
    .accents {
      color: $green-1;
    }
  }
  
  .planDescription {
    color: $blue-3;
    font-size: $txtDetail;
    text-align: left;
    width: 90%;
    margin: 0 auto;
    .text{
      font-size: $txtDetail;
    }
    .accents {
      color: $green-1;
      font-weight: 800;
    }
  }
  
  .planInstructions{
    width: 90%;
    margin: 0 auto;
    .text{
      font-size: $txtDetail;
      text-align: left;
    }
    
    .video {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px 0 15px 0;
    }
  }
}
