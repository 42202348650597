@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  color: $gray-4;
  width: 300px;
  border: 3px solid $blue-1;
  border-radius: 13px;

  .name {
    font-size: 29px;
    padding: 0px 5px;
    margin-top: 15px;
    color: $blue-3;
    font-weight: 700;
  }

  .description {
    font-size: 17px;
    padding: 1px 5px;
  }
  
  .cost {
    font-size: 27px;
    padding: 3px 5px;
    color: $blue-3;
    font-weight: 900;
  }
  
  .commentToCost {
    font-size: 15px;
  }
  
  .frequency {
    font-size: 17px;
    margin: 3px 0 0 0;
    padding: 1px 5px;
  }


  .link {
    font-size: 19px;
    background-color: $green-2;
    color: #fff;
    border: none;
    border-radius: 12px;
    margin: 20px 0px 0px 0px;
    padding: 10px 20px;
    cursor: pointer;

    /* Hover effect */
    &:hover {
      background-color: $blue-1;
    }

    /* Active state */
    &:active {
      background-color: $blue-3;
      transform: translateY(2px);
    }

    /* Disabled state */
    &:disabled {
      background-color: white;
      cursor: not-allowed;
    }
  }

  .benefits {
    text-align: left;
    align-self: flex-start;
    margin-top: auto;
    margin-left: 0px;
    margin-right: 14px;
  }

  ul {
    font-size: 17px;
    margin-right: 10px;
  }

  li {
    color: $blue-3;
    padding-bottom: 10px;
  }
}



