@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  @include width-viewport;

  .accordionContainer {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }

    .image {
      height: 400px;

      @media screen and (max-width: $breakpoint-xxl) {
        height: 300px;
      }
      @media screen and (max-width: $breakpoint-lg) {
        height: 250px;
      }
      @media screen and (max-width: $breakpoint-md) {
        height: 200px;
      }
      @media screen and (max-width: $breakpoint-sm) {
        height: 120px;
      }
    }
  }
}
