@import "styles/variables.scss";

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .contributionTabsContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .button {
      color: $green-4;
      background: inherit;
      font-weight: 700;
      border: 2px solid transparent;
      border-radius: 30px;
      padding: 6px 12px;

      &:hover {
        cursor: pointer;
      }
      
      &.selected {
        background: $blue-1;
        color: $white-1;
        border-color: $blue-1;
      }
    }
  }
  .innerContainer {
    display: flex;
    flex-direction: column;
    .graphContainer {
      min-height: 250px;
    }
    .secondSection {
      display: flex;
      margin-top: 20px;
      width: 100%;
      justify-content: space-around;
      align-items: center;

      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;
      }
      .donutChart {
        min-width: 300px;
        height: 350px;
        margin-bottom: 0;

        @media screen and (max-width: $breakpoint-xlg) {
          min-width: 250px;
          height: 300px;
        }
        @media screen and (max-width: $breakpoint-lg) {
          min-width: 200px;
          height: 250px;
        }
        @media screen and (max-width: $breakpoint-md) {
          margin-bottom: 20px;
        }
      }

      .datePicker {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $gray-9;
        font-size: 14px;
      }
    }
  }
}
