@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  color: $gray-4;

  .name {
    @include font-size-table-column-title();
    padding: 0px 5px;
    margin-top: 0px;
    color: $blue-3;
    font-weight: 700;
  }

  .description {
    @include font-size-table-row-title();
    margin-top: 5px;
    padding: 1px 5px;
    // height: 15px;
  }
  
  .cost {
    @include font-size-table-column-title();
    padding: 3px 5px;
    color: $blue-3;
    font-weight: 900;
  }
  
  .commentToCost {
    @include font-size-table-row-title();
    margin: 0px 5px 15px 5px;
    min-height: 30px;
  }
  
  .frequency {
    @include font-size-table-row-title();
    min-height: 30px;
    padding: 1px 5px;
    min-height: 60px;
  }


  .link {
    /* General style butoom */
    @include font-size-table-row-title();
    background-color: $green-2;
    color: #fff;
    border: none;
    border-radius: 12px;
    margin: 20px 0px 0px 0px;
    padding: 10px 20px;
    min-height: 80px;
    width: 90%;
    cursor: pointer;

    /* Hover effect */
    &:hover {
      background-color: $blue-1;
    }

    /* Active state */
    &:active {
      background-color: $blue-3;
      transform: translateY(2px);
    }

    /* Disabled state */
    &:disabled {
      background-color: white;
      cursor: not-allowed;
    }
  }

  .benefits {
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 14px;
  }

  ul {
    @include font-size-table-row-title();
    margin: 0 11px 0 37px;
  }

  li {
    color: $blue-2;
    padding-bottom: 7px;
  }
}

@media only screen and (min-width: $breakpoint-xl) {
  /* Styles for smaller devices */
  .description {
    min-height: 40px;
  }
}

@media only screen and (min-width: $breakpoint-xxl) {
  /* Styles for smaller devices */
  .description {
    min-height: 65px;
  }
}

