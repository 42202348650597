@import "styles/variables.scss";

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  .innerContainer {
    display: flex;
    flex-direction: column;
    .graphContainer {
      min-height: 250px;
    }
    .datePicker {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $gray-9;
      font-size: 14px;
    }
  }
}
