@import "../../styles/variables.scss";


.titleContainer {
  width: 100%;
  border: 1.5px solid $green-4;
  border-radius: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $green-4;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  position: relative;

  .accordionTitle {
    align-self: center;
    text-align: center;
    width: 100%;
  }

  .icon {
    align-self: center;
    display: flex;
    align-items: center;
  }
}

.titleContainerNoBorder {
  width: 100%;
  padding: 0.76rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 800;
  line-height: 24px;
  position: relative;
  color: white;
  background-color: $green-2;
  
  .accordionTitle {
    margin: 0 auto;
    padding-left: 75px;
    width: 100%;
    font-size: 1.3rem;
  }

  .icon {
    margin-left: 2rem;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .titleContainer {
    border: 1.5px solid $green-4;
    border-radius: 36px;
    padding: 12px 0px 12px 24px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .titleContainer {
    border: 2px solid $green-4;
    border-radius: 64px;
    padding: 18px 48px;
    font-size: 26px;
    font-weight: 500;
    line-height: 26px;

    .icon {
      display: flex;
      position: absolute;
      right: 12px;
      align-items: center;
    }
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .titleContainer {
    width: 60%;
    border: 3px solid $green-4;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $green-4;
    padding: 18px 72px;
    font-weight: 500;
    line-height: 32px;
    position: relative;
    align-self: center;

    .icon {
      display: flex;
      position: absolute;
      right: 16px;
      align-items: center;
    }
  }
}

@media screen and (min-width: $breakpoint-xxxl) {
  .titleContainer {
    font-size: 30px;
  }
}

.line {
  width: 90%;
  margin: 0 auto;
  border-bottom: 2px solid $green-2;
}
