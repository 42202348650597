@import "../../styles/variables.scss";


.container{
  margin: 0 auto;
}

.planContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns */
  grid-template-rows: 1fr 2fr 1fr 1f1 1fr 1fr;
}

@media only screen and (max-width: $breakpoint-xl) {
  .planContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on small devices */
  }
}