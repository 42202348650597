@import "styles/variables.scss";
@import "styles/mixins.scss";

.containerFAQSection {
  // @include width-viewport;
  align-self: center;
  margin: auto;
  
  .FAQSection {
    align-self: center;
    margin: 0 auto;
  }
}

.subtitle {
  font-size: 40px;
  margin: 70px auto 30px auto;
  font-weight: 700;
  color: $green-2;
  text-align: center;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 26px;
  }
}

.text {
  align-self: center;
  text-align: left;
  width: 60%;
  margin: auto;
  font-size: 12px;
  color: $blue-3;
  scroll-padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
}

@media screen and (min-width: $breakpoint-sm) {
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
}

@media screen and (min-width: $breakpoint-xxxl) {
  .text {
    font-size: 21px;
  }
}
