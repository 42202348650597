@import "styles/variables.scss";
.mainContainer {
  width: 100%;

  .resultMessage {
    color: $blue-3;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .message {
      border-radius: 10px;
      padding: 24px;
      background-color: $green-5;
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0px;
    }
  }

  .actions {
    color: $blue-3;
    font-size: 1rem;

    .individualAction {
      margin-bottom: 20px;
      text-align: justify;
      padding-left: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: $breakpoint-md) {
      margin: 25px;
    }
    @media screen and (max-width: $breakpoint-sm) {
      margin: 0;
    }
  }
}
