@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  @include width-viewport;

  .carouselContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .cardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}
