@import "../../styles/variables.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .video {
    margin-bottom: 2rem;
  }

  .button {
    margin-bottom: 40px;
  }

  .button .demoButton {
    border-radius: 9px;
    width: 300px;
    font-size: 20px;
  }

  .copyright {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .mainContainer {
    .button {
      margin-bottom: 40px;
    }

    .button .demoButton {
      border-radius: 9px;
      width: 500px;
      font-size: 20px;
    }
  }
}
