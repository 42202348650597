@import "styles/variables.scss";
@import "styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  padding: 0 50px;
  @include width-viewport;

  .carouselContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .cardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: $breakpoint-xxl) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-xlg) {
  .container {
    width: 100%;
    flex-direction: row;
    min-height: 200px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .container {
    width: 100%;
  }
}