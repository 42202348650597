@import "../../styles/variables.scss";

.ContactUsPage {
  background-color: white;
  display: grid;
  height: 100vh;
  margin: 120px auto 0 auto;
  place-items: center;
  width: 100%;
}

.mainBlock{
  .title{
    font-size: 40px;
    color: $green-2;
    width: 90%;
    margin: 0 auto 30px auto;
  }
}
